<template>
  <div>
    <div class="row">
      <div class="col-12">
        <input-select-search
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
          v-model="form.cursoId"
          ref="curso"
          required
        />
      </div>

      <div class="col-12">
        <input-select-search
          label="Curso antecessor"
          :options="opcoes.cursos"
          v-model="form.cursoAntecessorId"
        />
      </div>

      <div class="col-12">
        <input-select-search
          label="Grupo turma antecessor"
          :options="opcoes.grupoTurma"
          v-model="form.grupoTurmaAntecessorId"
        />
      </div>

      <div class="col-12">
        <input-text
          ref="descricao"
          v-model="form.descricao"
          type="text"
          :label="$t('ACADEMICO.NOME')"
          :placeholder="$t('ACADEMICO.NOME')"
          :maxLength="50"
          required
        />
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import GruposTurmaService from '@/common/services/academico/gruposTurma.service.js';

// Components:
import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        cursos: [],
        grupoTurma: [],
      },
    };
  },
  mounted() {
    this.getCursos();
    this.form.id ? this.getGrupoTurma() : '', this.$emit('refs', this.$refs);
  },
  watch: {
    'form.cursoAntecessorId': 'buscarGrupoTurmaPorCurso',
  },
  methods: {
    // FUNÇÕES PARA OBTENÇÃO DOS DADOS:

    buscarGrupoTurmaPorCurso() {
      this.getGrupoTurma();
    },

    getCursos() {
      this.$store.dispatch(START_LOADING);
      CursoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getGrupoTurma() {
      this.$store.dispatch(START_LOADING);
      GruposTurmaService.obterTodos({ cursoId: this.form.cursoAntecessorId })
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurma = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
