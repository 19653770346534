<template>
  <div class="row">
    <div class="col-12 col-md-3">
      <input-select-search
        :label="$t(`ACADEMICO.CURSO`)"
        :options="opcoes.cursos"
        v-model="filtro.cursoId"
      />
    </div>
    <div class="col-12 col-md-auto">
      <b-button class="botao-acao-filtro w-100" variant="secondary" @click="limparFiltro">
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import CursoService from '@/common/services/academico/curso.service';

// Components:
// import Filtro from '@/components/filtro/Filtro';
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        cursoId: '',
      },
      opcoes: {
        cursos: [],
      },
    };
  },
  mounted() {
    this.getCursos();
    this.$emit('refs', this.$refs);
  },
  watch: {
    filtro: {
      handler(filtro) {
        this.$emit('filtrar', JSON.parse(JSON.stringify(filtro)));
      },
      deep: true,
    },
  },
  methods: {
    // FUNÇÕES PARA OBTENÇÃO DOS DADOS:
    getCursos() {
      this.$store.dispatch(START_LOADING);
      CursoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          data.sort((a, b) => a.text.localeCompare(b.text));
          this.opcoes.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES FILTRO:
    limparFiltro() {
      this.filtro = {
        cursoId: '',
      };
      this.$emit('limparFiltro');
    },
  },
};
</script>