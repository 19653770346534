<template>
  <modal
    id="modalGruposTurma"
    :exibir="exibir"
    :titulo="'Grupos de turmas'"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-grupo-turma :form="form" @refs="capturarRefs" />
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>

        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import GruposTurmaService from '@/common/services/academico/gruposTurma.service.js';

// Components:
import Modal from '@/components/modal/Modal.vue';
import FormGrupoTurma from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormGrupoTurma,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.form.id ? this.editar() : this.criar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      GruposTurmaService.criar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO', 'Grupo turma criado!'));
          this.$emit('atualizar-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      GruposTurmaService.editar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO', 'Grupo turma atualizado!'));
          this.$emit('atualizar-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    limparModal() {
      this.form.cursoAntecessorId = '';
      this.form.cursoId = '';
      this.form.descricao = '';
      this.form.grupoTurmaAntecessorId = '';
    },
    fecharModal() {
      this.limparModal();
      this.$emit('fechar');
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
  },
};
</script>
