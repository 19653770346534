<template>
  <div>
    <titulo-grupos-turma @cadastrar="openModal" />

    <filtro @filtrar="filtrar" />

    <tabela-grupos-turma
      :items="gruposTurmas"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <modal-grupos-turma
      :exibir="modais.gruposTurma"
      :form="modais.gruposTurmaDados"
      @atualizar-tabela="filtrar"
      @fechar="closeModal('gruposTurma')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services & Models:
import GruposTurmaService from '@/common/services/academico/gruposTurma.service.js';

// Components:
import TituloGruposTurma from './components/Titulo';
import Filtro from './components/Filtro';
import TabelaGruposTurma from './components/Tabela';
import ModalGruposTurma from './components/Modal';

export default {
  components: {
    TituloGruposTurma,
    Filtro,
    TabelaGruposTurma,
    ModalGruposTurma,
  },
  data() {
    return {
      gruposTurmas: [],
      modais: {
        gruposTurma: false,
        gruposTurmaDados: {},
      },
      filtro: {},
    };
  },
  methods: {
    // FUNÇÕES FILTRO:
    filtrar(filtroUtilizado) {
      filtroUtilizado ? (this.filtro = filtroUtilizado) : {};

      this.$store.dispatch(START_LOADING);
      GruposTurmaService.obterTodos(this.filtro)
        .then(({ data }) => {
          this.gruposTurmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CONFIRMAR_EXCLUSAO_DE').formatUnicorn({
          item: item.descricao,
        }),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item.id);
      }
    },
    excluir(itemId) {
      this.$store.dispatch(START_LOADING);
      GruposTurmaService.excluir(itemId)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data);
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
      this.modais[key] = true;
    },
    closeModal(key) {
      this.modais[key] = false;
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
